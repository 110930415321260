.tiptap {
    .code-block {
      position: relative;
    
      select {
        position: absolute;
        background-color: white;
        right: 0.5rem;
        top: 0.5rem;
      }
    }
  }
.comment-section {
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 6px;
  background-color: #f9f9f9;
  font-size: 0.9rem;
}

.toggle-button {
  background: none;
  color: #007bff;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
}

.toggle-button:hover {
  color: #0056b3;
}

.comments-container {
  margin-top: 0.5em;
}

.comments-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.comment-item {
  background-color: #fff;
  padding: 0.5em;
  margin-bottom: 0.25em;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.comment-text {
  font-size: 0.85rem;
  margin-bottom: 0.25em;
}

.comment-meta {
  font-size: 0.75rem;
  color: #666;
}

.no-comments {
  font-style: italic;
  color: #666;
  font-size: 0.85rem;
}

.add-comment {
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.input-row {
  display: flex;
  gap: 0.5em;
}

.comment-username {
  flex: 2;
  padding: 0.25em;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.85rem;
}

.comment-username:focus {
  border-color: #007bff;
  outline: none;
}

.comment-textarea {
  flex: 5;
  padding: 0.25em;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.85rem;
  resize: none;
}

.comment-textarea:focus {
  border-color: #007bff;
  outline: none;
}

.add-comment-button {
  flex: 1;
  background-color: #28a745;
  color: white;
  padding: 0.25em 0.5em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85rem;
}

.add-comment-button:hover {
  background-color: #218838;
}

.error {
  color: red;
  font-size: 0.75rem;
  display: block;
  margin-top: -0.5em;
}

@import '~remixicon/fonts/remixicon.css';


@font-face {
  font-family: 'JetBrainsMono';
  src: url('../../../../fonts/webfonts/JetBrainsMono-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
  /* CSS for an input box */
.ProseMirror {  
  background-color: white;      
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: small;
  margin-bottom: 10px;
}

/* General button styling */
.button-group button {
  background: none;
  border: none;
  padding: 8px;
  font-size: 1.5em;
  cursor: pointer;
  color: #666; /* Default color */
  position: relative;
}

/* Highlight active buttons */
.button-group button.is-active {
  color: #000000;
}

/* Dim disabled buttons */
.button-group button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

/* Hover effect */
.button-group button:not(:disabled):hover {
  color: #227aff; /* Hover color */
}

/* Tooltip styling */
.button-group button[title] {
  position: relative;
}

.button-group button[title]:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.5em;
  white-space: nowrap;
  pointer-events: none;
  opacity: 1;
  z-index: 100;
}

.button-group button[title]::after,
.button-group button[title]::before {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.button-group button[title]:hover::after,
.button-group button[title]:hover::before {
  opacity: 1;
}

.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul, 
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
    
    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h2{
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
    font-size: 1.2rem; 
  }


  pre {
    background: rgb(70, 70, 70);
    border-radius: 0.5rem;
    color: white;
    font-family: 'JetBrainsMono', monospace !important;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      font-family: 'JetBrainsMono', monospace !important;
      padding: 0;
    }

    /* Code styling */
    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }

  blockquote {
    border-left: 3px solid gray;
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid gray;
    margin: 1rem 0;
  }
}
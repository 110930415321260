/* All the CSS related to answers Page*/
.answer {
  display: flex;
  flex-direction: row;
  border-bottom: #000000 1px dashed;
}

.answerText {
  width: 75%;
  /* List styles */
  ul, 
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
    
    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h2{
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
    font-size: 1.2rem; 
  }

  pre {
    background: rgb(70, 70, 70);
    border-radius: 0.5rem;
    color: white;
    font-family: 'JetBrainsMono', monospace !important;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      font-family: 'JetBrainsMono', monospace !important;
      padding: 0;
    }

    /* Code styling */
    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }

  blockquote {
    border-left: 3px solid gray;
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid gray;
    margin: 1rem 0;
  }
}

.answerAuthor {
  margin-left: 5%;
}

.answer_author {
  color: green;
}

a {
  color: red;
}

.btns {
  margin-right: 15%;
}

.chat_list:last-child {
  border-bottom: #000000 1px dashed;
}

.chat {
  display: flex;
  flex-direction: row;
  border-top: #000000 1px dashed;
  height: max-content;
  align-items: center;
  justify-content: space-between;
}

.chat-me {
  background-color: #ddd;
}

.message-meta {
  height: 100%;
}

.message-meta * {
  padding: 0;
  margin: 0;
}

#msg-user {
  color: red;
  font-size: larger;
}

#msg-datetime {
  font-weight: 100;
  font-size: medium;
}

.chat * {
  padding-left: 2%;
  padding-right: 2%;
}

.add-message {
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  height: 50px;
}

.add-message * {
  height: 100%;
}

.send-message-button {
  flex: 1;
  background-color: #28a745;
  color: white;
  padding: 0.25em 0.5em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85rem;
}

.message-meta {
  width: 15%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.message-text {
  width: 70%;
  text-wrap: wrap;
  word-break: break-all;
  white-space: normal;
  margin-top: 15px;
}

.message-textarea {
  flex: 5;
  padding: 0.25em;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.85rem;
  resize: none;
}

#chat_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.input-row {
  align-items: center;
}

#message_list {
  overflow: scroll;
  flex-grow: 1;
}

#right_main:has(#chat_container) {
  overflow: hidden;
}
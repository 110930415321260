/* Add CSS */

/* Add global CSS here */
/* Add CSS stylesheet */


html {
  height: 99%;
}

body,
#root {
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
}

.tagNode {
  place-self: center;
  border: #000000 1px dashed;
  padding: 5%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.tagName {
  color: cornflowerblue;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f7f7;
}

.container h2,
.container p,
.container h4,
.container form {
  text-align: center;
  width: 100%;
  margin: 10px 0;
}

.input-form-error {
  color: red;
}

.form-container {
  width: 100%;
  max-width: 500px;
}

.input-text {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 300px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
}

.input-text:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.login-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.login-button:hover {
  background-color: #0056b3;
}

.login-button:active {
  background-color: #003f7f;
}

.login-register-link {
  color: #007bff;
  text-decoration: none;
}

.login-register-link:hover {
  color: #003f7f;
}

.social-login-link {
  padding: 5px 20px 5px 10px;
  font-size: 16px;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 0 0;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 175px;
}

.social-login-image {
  width: 20px;
  margin: 5px 10px 5px 0;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10%;
  width: 100%;
  background: #dddddd;
}

.spacer {
  width: 25%;
}

.title {
  font-size: 32px;
  font-weight: 800;
  display: flex;
  align-items: center;
}

.searchBar {
  height: 10%;
}

.headerUtilities {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 500px;
}

.headerUtilities button {
  width: 120px;
}

.headerButton {
  display: flex;
  float: left;
}

.headerLogo {
  width: 90px;
}
.btns {
  margin-right: 15%;
}

.chat_list:last-child {
  border-bottom: #000000 1px dashed;
}

.chat {
  display: flex;
  flex-direction: row;
  border-top: #000000 1px dashed;
  height: 50px;
  align-items: center;
  justify-content: space-between;
}

.chat * {
  padding-left: 2%;
  padding-right: 2%;
}

.new-chat {
  max-height: 100px;
  padding-top: 15px;
}

.input-row {
  height: 50px;
}

.input-row * {
  height: 100%;
}

.create-chat-button {
  flex: 1;
  background-color: #28a745;
  color: white;
  padding: 0.25em 0.5em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85rem;
}

#inbox_container {
  height: 100%;
  display: flex;
  flex-direction: column
}

.input-row {
  align-items: center;
  margin-bottom: 10px;
}

#chat_list {
  overflow: scroll;
  flex-grow: 1;
}

#right_main:has(#chat_container) {
  overflow: hidden;
}

.error-inbox {
  color: red;
  font-size: 1.5rem;
  display: block;
  /* margin-top: -0.5em; */
}
.vote-container {
  display: flex;
  align-items: center;
  padding: 12px;
}

.vote-button {
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.125rem;
  background-color: #f0f0f0;
  cursor: pointer;
  outline: none;
}

.vote-button-upvoted {
  background-color: #38a169;
  color: white;
}

.vote-button-downvoted {
  background-color: #e53e3e;
  color: white;
}

.vote-count {
  margin-left: 0.5rem;
}

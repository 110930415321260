.profile-page {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
  }
  
  .profile-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
  }
  
  .profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  .username {
    font-size: 24px;
    color: #333;
  }
  
  .bio-section {
    margin-top: 20px;
    width: 100%;
    word-wrap: break-word;
  }
  
  .bio {
    font-size: 16px;
    color: #555;
  }
  
  .bio-textarea {
    font-family: Arial, sans-serif;
    width: 100%;
    font-size: 16px;
    padding: 8px;
    margin-top: 10px;
  }
  
  .bio-button-group, .edit-button {
    margin-top: 10px;
  }
  
  .save-button, .cancel-button, .edit-button, .chat-button {
    padding: 8px 12px;
    margin-right: 5px;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 4px;
  }
  
  .save-button {
    background-color: #4CAF50;
  }
  
  .cancel-button {
    background-color: #f44336;
  }
  
  .edit-button, .chat-button {
    background-color: #007bff;
  }

  .chat-button {
    height: 30px;
  }

  .start-chat-error {
    color: red;
  }
  
  .stats-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
  
  .stat {
    text-align: center;
    display: block;
    margin-top: 5px;
    padding: 0;
  }
  
  .label {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  
  .value {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #007bff;
    float: right;
  }

  .question_list {
    margin-top: 20px;
  }

  .pfp{
    width: 50px;
  }
